import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const StoreSelector = ({ goToDisplayVideo }) => {
  const [storeOptions, setStoreOptions] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(true); // Open pop-up immediately
  const [selectedDistributor, setSelectedDistributor] = useState(null); // Track selected distributor
  const dispatch = useDispatch();

  // Get distributor info from Redux state
  const distributor_info = useSelector(state => state.distributor_info);
  
  // Extract the distributor logos and details
  const distributors = distributor_info.distributor_info || [];

  // If there is only one distributor, automatically select it and close the popup
  useEffect(() => {
    if (distributors.length === 1) {
      setSelectedDistributor(distributors[0]);
      setIsPopupOpen(false); // Close pop-up immediately if one distributor
      dispatch({ type: 'SELECTED_DISTRIBUTOR_LOGO', payload: distributors[0]["Distributor Logo"] });
      dispatch({ type: 'SELECTED_DISTRIBUTOR_NAME', payload: distributors[0]["Distributor Name"] });
      dispatch({ type: 'SELECTED_DISTRIBUTOR_TYPE', payload: distributors[0]["Distributor Type"] });
    }
  }, [distributors]);

  // Set store options based on selected distributor
  useEffect(() => {
    if (selectedDistributor) {
      const stores = selectedDistributor["Stores"] || [];
      setStoreOptions(stores);
    }
  }, [selectedDistributor]);

  const handleDistributorSelect = (distributor, index) => {
    setSelectedDistributor(distributor);
    setIsPopupOpen(false); // Close pop-up when distributor is selected
    dispatch({ type: 'SELECTED_DISTRIBUTOR_LOGO', payload: distributor["Distributor Logo"] }); // Dispatch selected distributor index
    dispatch({ type: 'SELECTED_DISTRIBUTOR_NAME', payload: distributor["Distributor Name"] });
    dispatch({ type: 'SELECTED_DISTRIBUTOR_TYPE', payload: distributor["Distributor Type"] });
  };

  const handleSubmit = () => {
    if (selectedStore || storeOptions.length === 1) {
      let storeID;
  
      // Case 1: If a store is selected from the dropdown
      if (selectedStore) {
        const selectedStoreDetails = storeOptions.find(store => store["Store Address"] === selectedStore);
        storeID = selectedStoreDetails?.StoreID;

        if (selectedStore === "Pl. du Môle 3, 1420 Braine-l'Alleud") {
          alert("Impossible de continuer avec cette sélection de magasin. Veuillez essayer un autre magasin.");
          return; // Block further execution
        }
      }
  
      // Case 2: If there is only one store, we automatically use its StoreID
      if (storeOptions.length === 1 && !storeID) {
        storeID = storeOptions[0]?.StoreID;
      }
  
      // Dispatch the storeID to Redux
      if (storeID) {
        dispatch({ type: 'STORE_ID', payload: storeID });
        goToDisplayVideo();
        
      //   // Geolocation check
      //   const storeGPS = storeOptions.find(store => store.StoreID === storeID)?.["Store GPS"];
  
      //   if (!navigator.geolocation) {
      //     alert('La géolocalisation n\'est pas prise en charge par votre navigateur. Veuillez l\'activer.');
      //     return;
      //   }
  
      //   navigator.geolocation.getCurrentPosition(
      //     (position) => {
      //       const userLatitude = position.coords.latitude;
      //       const userLongitude = position.coords.longitude;
      //       console.log("Store coordinates", storeGPS[0], storeGPS[1] );
      //       console.log("User coordinates", userLatitude, userLongitude );
      //       const isNearby =
      //         Math.abs(userLatitude - storeGPS[0]) < 0.02 &&
      //         Math.abs(userLongitude - storeGPS[1]) < 0.02;
      //       if (isNearby) {
      //         alert('Vous êtes autorisé à continuer!');
      //         goToDisplayVideo();
      //       } else {
      //         alert('Vous n\'êtes pas autorisé à poursuivre cette sélection. Veuillez vérifier vos informations et réessayer');
      //       }
      //     }
      //   );
      } else {
        alert('Veuillez sélectionner un magasin!');
      }
    } else {
      alert('Veuillez sélectionner un magasin!');
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      padding: '0 10%',
    }}>
      {isPopupOpen ? (
        // Distributor Selection Popup (Visible immediately if there are multiple distributors)
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
        }}>
          <h3>Veuillez sélectionner un distributeur</h3>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {distributors.map((distributor, index) => (
              <div 
                key={index} 
                onClick={() => handleDistributorSelect(distributor)} 
                style={{
                  cursor: 'pointer',
                  marginBottom: '10px',
                  border: '1px solid #ccc',
                  padding: '10px',
                  borderRadius: '8px',
                  textAlign: 'center',
                  width: '150px',
                  transition: 'all 0.3s',
                }}>
                <img
                  src={`data:image/png;base64,${distributor["Distributor Logo"]}`}
                  alt={distributor["Distributor Name"]}
                  style={{
                    width: '100px',
                    height: '100px',
                    objectFit: 'contain',
                  }}
                />
                <p>{distributor["Distributor Name"]}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        // Main Store Selector Page (Only renders after distributor is selected)
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* Logo */}
          {selectedDistributor?.["Distributor Logo"] && (
            <img
              src={`data:image/png;base64,${selectedDistributor["Distributor Logo"]}`}
              alt={`${selectedDistributor["Distributor Name"]} Logo`}
              style={{
                width: '200px',
                height: '200px',
                objectFit: 'contain',
                marginBottom: '1rem',
              }}
            />
          )}

          {/* Welcome Text */}
          {selectedDistributor && (
            <h2 style={{
              textAlign: 'center',
              fontSize: 'clamp(1.5rem, 2vw, 2.5rem)',
              marginBottom: '1rem',
            }}>
              Bienvenue chez {selectedDistributor["Distributor Name"]}! Sélectionnez votre magasin/emplacement
            </h2>
          )}

          {/* Store Selection */}
          {storeOptions.length === 1 ? (
            <div style={{
              padding: '0.5rem',
              fontSize: '1rem',
              width: '80%',
              maxWidth: '300px',
              marginBottom: '2rem',
              textAlign: 'center',
              backgroundColor: '#f9f9f9',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}>
              {storeOptions[0]["Store Address"]}
            </div>
          ) : (
            <select
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
              style={{
                padding: '0.5rem',
                fontSize: '1rem',
                width: '80%',
                maxWidth: '300px',
                marginBottom: '2rem',
              }}
            >
              <option value="" disabled>Sélectionnez votre magasin/emplacement</option>
              {storeOptions.map((store, index) => (
                <option key={index} value={store["Store Address"]}>
                  {store["Store Address"]}
                </option>
              ))}
            </select>
          )}

          <div className="submit-container">
            <div className="white-banner">
              <button className="submit-button-fixed" onClick={handleSubmit} style={{ bottom: '10%' }}>Confirmer</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreSelector;