import React, { useState } from 'react';
import '../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import MultiStepMenu from '../MultiStepMenu';
import AlertMessage from '../AlertMessage';
import '../AlertMessage.css';

const HairGoal = ({ goToNextStep, goToPrevStep }) => {
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectionLimitAlert, setSelectionLimitAlert] = useState(false);
  const dispatch = useDispatch();
  const [showErrorAlert, setShowErrorAlert] = useState(false);


  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleNextClick = () => {
    if (selectedButtons.length > 0) {
      dispatch({ type: 'SAVED_HAIR_GOALS', payload: selectedButtons });
      goToNextStep();
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleButtonClick = (buttonText) => {
    if (selectedButtons.includes(buttonText)) {
      setSelectedButtons(selectedButtons.filter(button => button !== buttonText));
      setSelectionLimitAlert(false);
    } else {
      if (selectedButtons.length < 3) {
        setSelectedButtons([...selectedButtons, buttonText]);
        setSelectionLimitAlert(false);
      } else {
        setSelectionLimitAlert(true);
      }
    }
  };

  return (
    <div className="container">
      <div className="fixed-header">
        <MultiStepMenu step={4} numberOfSteps={6} />
        <h2 style={{ position: 'sticky', backgroundColor: 'white', padding: '10px', zIndex: 1, marginTop: '10%', fontSize: 'clamp(1.5rem, 2vw, 2.5rem)', textAlign: 'center'}}>
            Sélectionnez au plus 3 de vos objectifs capillaires​
          </h2>
      </div>
      <div className="form" style={{marginTop: '25%'}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '500px', overflowY: 'auto',  scrollbarWidth: 'none',  msOverflowStyle: 'none'}}>
            {['Augmenter la brillance', 'Cheveux colorés', 'Cheveux plus forts', 'Cheveux sains', 'Définition des boucles', 'Longueur',
            'Protection thermique', 'Réparation', 'Rétention d\'humidité', 'Santé capillaire',  'Traitement du cuir chevelu', 'Aucun'
              ].map(buttonText => (
                <button
                  className="submit-button"
                  key={buttonText}
                  style={{
                    backgroundColor: selectedButtons.includes(buttonText) ? '#d49292' : 'black',
                    margin: '5%',
                    // width: '80%',
                    height: '10%',
                    width: '250px'
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleButtonClick(buttonText);
                  }}
                >
                  <span>{buttonText}</span>
                </button>
              ))}
          </div>
          {selectionLimitAlert && (
            <p style={{ color: 'red' }}>
              Vous ne pouvez sélectionner que 3 objectifs.
            </p>
          )}
        </div>

        {showAlert && (
          <AlertMessage
          message="Êtes-vous sûr de vouloir continuer ?"
          subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
          onConfirm={() =>
              {
                setShowAlert(false);
                goToPrevStep();
              }
              }
          onCancel={() => {
                setShowAlert(false);
            }}
          />
        )}

        {showErrorAlert && (
        <AlertMessage
          message="Veuillez sélectionner les objectifs capillaires appropriés."
          // subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées. "
          onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}

        {/* Navigation buttons */}
        <div className="footer" style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>
    // </div>
  );
};

export default HairGoal;