import React, { useState, useEffect } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'; // For fetching images from Azure Blob Storage
import { setProductIndex } from '../store';
import AlertMessage from './AlertMessage';
import './AlertMessage.css';

const ProductMatchTrue = ({ goToNoPage, goToTellMeMore, goToYesPage }) => {
  const dispatch = useDispatch();
  const [productImages, setProductImages] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);// State to track selected index
  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const [showDataAlert, setShowDataAlert] = useState(false); // State for data confirmation alert
  const [apiCallInProgress, setApiCallInProgress] = useState(false); // State to track API call progress
  const [isSelectedIndex, setIsSelectedIndex] = useState(false);
  const productIndex = useSelector(state => state.productIndex);
  const productRecommendations = useSelector(state => state.productRecommendations);
  const diagnosticsLLMText = useSelector(state => state.diagnosticsLLMText);
  const saved_answers = useSelector(state => state.saved_answers);
  const saved_constraints = useSelector(state => state.saved_constraints);
  const [showAlert, setShowAlert] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const distributor_info = useSelector(state => state.distributor_info);
  const selected_distributor_name = useSelector(state => state.selected_distributor_name);
  const selected_distributor_type = useSelector(state => state.selected_distributor_type);

  // Function to get images from Azure Blob Storage
  const fetchProductImage = async (productName, productCategory, productBrand, isMounted) => {

    const sasToken = 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-11-29T05:23:17Z&st=2024-11-28T21:23:17Z&spr=https&sig=45AdiN%2B6gdDiW2boduqpS6z3PT050M56kQTRfvfXUHc%3D';
    const encodedBrandName = encodeURIComponent(productBrand);
    const encodedProductName = encodeURIComponent(productName);
    const azureBlobUrl = `https://halcosgwcblobv1.blob.core.windows.net/halcosgwcproductimages/${encodedBrandName}-${encodedProductName}.png?${sasToken}`;

    try {
      const response = await axios.get(azureBlobUrl);
      return isMounted ? response.config.url : null; // Only return the URL if the component is still mounted
    } catch (error) {
      // Attempt to fetch category-based image if product-specific fetch fails
      const encodedProductCategory = encodeURIComponent(productCategory.replace(/'/g, "%27")); // Double encoding for category name
      const categoryImageUrl = `https://halcosgwcblobv1.blob.core.windows.net/halcosgwcproductimages/Placeholder-${encodedProductCategory}.png?${sasToken}`;

      try {
        const categoryResponse = await axios.get(categoryImageUrl);
        return isMounted ? categoryResponse.config.url : null;
      } catch (categoryError) {
        return null;
      }
    }
  };

  // Fetch images for all products on mount
  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted
    const fetchImages = async () => {
      const images = {};
      for (let i = 0; i < productRecommendations['product_name'].length; i++) {
        const product = productRecommendations['product_name'][i];
        const brand = productRecommendations['product_brand'][i];
        const category = productRecommendations['product_category'][i]; // Get the product category
        const imageUrl = await fetchProductImage(product, category, brand, isMounted);
        if (imageUrl) {
          images[product] = imageUrl; // Store valid image URLs
        }
      }
      if (isMounted) setProductImages(images); // Only update state if mounted
    };

    if (productRecommendations['product_name'] && productRecommendations['product_name'].length > 0) {
      fetchImages();
    }

    return () => {
      isMounted = false; // Cleanup the effect on unmount
    };
  }, [productRecommendations]);



  const handlePasEncore = () => {
      handleButtonClick(productRecommendations['vendor_id']);
      goToNoPage();
  };

  const handleEnSavoir = () => {
    if (isSelectedIndex) {
      handleButtonClick([productRecommendations['vendor_id'][productIndex]]);
      generateProductPrompt();
      goToTellMeMore();
    } else {
      setShowErrorAlert(true);
    }
  };


  const generateProductPrompt = () => {
    setSuccessfulValidation(true);
    setShowDataAlert(true);
    let data =
    {language: "FR",
      answers: saved_answers,
      contraindications: saved_constraints,
      encoded_category:productRecommendations['product_category'][productIndex]};
    axios
      .post("https://hal-cos-prd-gwc-fnapp-v1.azurewebsites.net/api/createproductprompt", (data),
      {headers: {
        'Content-Type': 'application/json; charset=utf-8' }})
      .then((data) => {
          let resp = data.data;
          setApiCallInProgress(false);
          console.log(resp);
            // Check if `price_filtered` exists and its length is greater than 0
          if (resp['llm_output']) {
            console.log('reached here - positive');
            dispatch({ type: 'PRODUCT_LLM_TEXT', payload:resp['llm_output']});
          } else {
            console.log('reached here - negative');
            // Assuming this navigates to failure page
            }
          })
          .catch((error) => {
              setApiCallInProgress(false); // Stop spinner
              console.error("An error occurred during the API request:", error);
              alert("Une erreur est survenue lors de l'envoi de vos données. Veuillez réessayer plus tard.");
          });
      };


  const handlePret = async () => {
    if (isSelectedIndex) {
      await handleButtonClick([productRecommendations['vendor_id'][productIndex]]);
      if (selected_distributor_type === "event") {
        setApiCallInProgress(true);
        let data = {language: "FR", distributor_name: selected_distributor_name};
        axios.post("https://hal-cos-prd-gwc-fnapp-v1.azurewebsites.net/api/readticketnumber", (data),
        {headers: {
          'Content-Type': 'application/json; charset=utf-8' }})
        .then((data) => {
            let resp = data.data;
            setApiCallInProgress(false);
            dispatch({ type: 'TICKET_NUMBER', payload: resp['ticket_number_display']});
            goToYesPage();
          })
          .catch((error) => {
              setApiCallInProgress(false); // Stop spinner
              alert("Une erreur est survenue lors de l'envoi de vos données. Veuillez réessayer plus tard.");
        })}
        else {
          goToYesPage();
        };
    }
    else {
      setShowErrorAlert(true);
    };
  };

  const handleImageClick = (index) => {
    setIsSelectedIndex(true);
    setSelectedIndex(index); // Set the selected index in local state
    dispatch(setProductIndex(index)); // Dispatch to Redux as well
  };

  const handleButtonClick = async (vendorList) => {
    console.log("inside this function now");
    // save product details
    const product = productRecommendations['product_name'][productIndex];
    const brand = productRecommendations['product_brand'][productIndex];
    const price = productRecommendations['price_filtered'][productIndex];
    const quantity = productRecommendations['product_quantity'][productIndex];
    const imageUrl = productImages[product];

    // Fetch vendor details
    await fetchVendorDetails(vendorList);

    // Dispatch actions to save selected product details in Redux store
    dispatch({
      type: 'SAVE_SELECTED_PRODUCT',
      payload: { brand, price, quantity, name: product, imageUrl },
    });
    console.log("dispatched selected saved data");
  };

  const fetchVendorDetails = async (vendorList) => {
    setSuccessfulValidation(true);
    setShowDataAlert(true);
    const data = {
      language: "FR",
      vendor_id: vendorList,
    };
    console.log('input for vendor details:', data)
    let isMounted = true; // Track if the component is still mounted

    try {
      const response = await axios.post(
        "https://hal-cos-prd-gwc-fnapp-v1.azurewebsites.net/api/readVendorDetails",
        data,
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      );
      if (isMounted) {
        const resp = response.data;
        dispatch({ type: 'SET_VENDOR_DETAILS', payload: resp });
        setApiCallInProgress(false);
      }
    } catch (error) {
      if (isMounted) {
        setApiCallInProgress(false); // Stop spinner
        console.error("An error occurred during the API request:", error);
        alert("Une erreur est survenue lors de l'envoi de vos données. Veuillez réessayer plus tard.");
      }
    }

    return () => {
      isMounted = false; // Cleanup the effect on unmount
    };
  };

  return (
    <div className="container" >
      <div className="form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ fontSize: '2.0rem' }}>Découvrez votre produit capillaire idéal!​</h2>
          {/* Conditional rendering of the AlertMessage */}
        {showAlert && (
          <AlertMessage
            message={<span style={{ display: 'block', marginLeft: '1.2vw', fontSize: '2.5vw', marginTop: '5%', marginBottom: '8%', textTransform: 'uppercase', fontWeight: 'bold',}}>{"Découvrez votre diagnostique capillaire"}</span>}
            subtext={
              <div style={{ textAlign: 'left', width: '100%' }}>
                {diagnosticsLLMText.split('\n').map((line, index) => {
                  const [header, ...rest] = line.split(':');
                  const content = rest.join(':').trim();
                  return (
                    <React.Fragment key={index}>
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '2.2vw',
                          textTransform: 'uppercase',
                          marginTop: '2%',
                        }}
                      >
                        {header} :
                      </span>
                      <span style={{ display: 'block', marginLeft: '1.2vw', fontSize: '2.1vw' }}>
                        {content}
                      </span>
                      <br />
                    </React.Fragment>
                  );
                })}
              </div>
            }
            onConfirm={() => setShowAlert(false)} // Hide the alert on confirm
          />
        )}
      {showErrorAlert && (
        <AlertMessage
          message="Veuillez d'abord sélectionner un produit avant de continuer."
          // subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées. "
          onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}
      {/* Display product images, prices, and ingredients */}
      <div className="products-container" style={styles.productsContainer} >
        {productRecommendations['product_name'] && productRecommendations['product_name'].map((product, index) => {
          const imageUrl = productImages[product]; // Get the image URL
          const price = productRecommendations['price_filtered'][index]; // Get the price
          const quantity = productRecommendations['product_quantity'][index]; // Get the price
          const ingredients = productRecommendations['product_ingredients'][index]; // Get the ingredients
          const brand = productRecommendations['product_brand'][index];

          return (
            <div key={index} className="product-card" style={styles.productCard}>
              <div
                style={{ cursor: 'pointer', border: selectedIndex === index ? '2px solid #d49292' : 'none' }} // Optional border for selected image
                onClick={() => {
                  handleImageClick(index);  // Call the function
                }}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt={product} style={styles.productImage} />
                ) : (
                  <div style={styles.imagePlaceholder}>Image not available</div>
                )}
              </div>
              <div style={styles.productInfo}>
                <div style={styles.productName}>{product}</div>
                <div style={{...styles.productBrand, fontSize: '2.5vw', fontWeight: 'bold'}}>{brand}</div>
                <div style={{ ...styles.productPrice, fontSize: '4vw', fontWeight: 'bold', color: '#d49292' }}>
                  {price} €
                </div>
                <div style={{...styles.productIngredients, fontSize: '2.5vw'}}>{quantity}</div>
                <div style={{...styles.productIngredients, fontSize: '2.5vw'}}>{ingredients.slice(0, 3).join(', ')}</div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Bold Text */}
      <div style={styles.headingContainer}>
        <h2 style={{ fontWeight: 'bold', fontSize: '1.8rem' }}>Êtes-vous prêt(e) à récupérer votre produit ?​</h2>
      </div>

      {/* Buttons */}
      <div className="submit-container">
        <div className="white-banner" >
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', width: '100%', marginTop: '20px'}}>
              <button onClick={handlePasEncore} style={{ ...styles.button,  backgroundColor: 'black', fontSize: '3.3vw' }}>Non</button>
              <button onClick={handleEnSavoir} style={{ ...styles.button, backgroundColor: 'black', fontSize: '3.3vw' }}>En savoir plus</button>
              <button onClick={handlePret} style={{ ...styles.button, backgroundColor: '#d49292', fontSize: '3.3vw' }}>Oui</button>
            </div>
        </div>
      </div>
      <div
        className="footer hide-on-print"
        style={{
          backgroundColor: 'white',
          borderColor: 'white',
          boxShadow: 'none'
        }}
      >
        {/* <p className="footer-text">
          Les recommandations de produits sont fournies à titre informatif uniquement. <br/>
          Neotex décline toute responsabilité quant à leur utilisation ou à leurs résultats.
        </p> */}
      </div>
    </div>
  </div>
  );
};

// Styling using JS objects
const styles = {
  productsContainer: {
    display: 'flex',
    flexWrap: 'wrap', // Allows items to wrap to the next line
    justifyContent: 'space-around', // Space out the product cards evenly
    width: '100%', // Full width of the container
    marginTop: '-5%'
  },
  productCard: {
    margin: '5%',
    maxWidth: '20%', // Set product card width to 30% to allow 3 items per row
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the content in each product card
  },
  productImage: {
    width: '100%', // The image will take 100% of the product card's width
    height: '150px', // Fixed height for images
    objectFit: 'cover', // Ensure the image covers the container without distortion
  },
  imagePlaceholder: {
    width: '100%',
    height: '200px',
    backgroundColor: '#f0f0f0',
  },
  productInfo: {
    width: '150%', // This ensures that the product info is as wide as the image area
    textAlign: 'center',
    marginTop: '2%',
  },
  button: {
    backgroundColor: '#d49292',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '20px',
    height: '60px',
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '-5%'
  },
  headingContainer: {
    marginTop: '0%', // This ensures the heading is 5% below the product blocks
  },
};

export default ProductMatchTrue;