import React, { useState, useEffect } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';

const renderLoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

const YesPage = ({ goToRatingsPage }) => {
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // State to control the visibility of the alert
  const [showDataAlert, setShowDataAlert] = useState(false); // State for data confirmation alert
  const [apiCallInProgress, setApiCallInProgress] = useState(false); // State to track API call progress
  const [downloadingPDF, setDownloadingPDF] = useState(false); // State to track PDF downloading
  const [enableButton, setEnableButton] = useState(false); // State to enable/disable buttons
  const vendorDetails = useSelector(state => state.vendorDetails);
  const ticketNumber =  useSelector(state => state.ticketNumber); 
  const savedProduct = useSelector(state => state.savedProduct);
  const selected_distributor_type = useSelector(state => state.selected_distributor_type);
  console.log("savedProduct:", savedProduct);

  const handleSubmit = () => {
    setSuccessfulValidation(true);
    setShowDataAlert(true);
    dispatch({ type: 'SET_LAST_ACTION', payload: 'Purchase_Yes' });
    goToRatingsPage();
  };

  return (
    <div className="container">
      <div className="form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* First h2 - stays the same */}
        <h2 style={{fontSize: '2.5rem'}}>Merci de rendre votre parcours beauté encore plus enrichissant avec Halisi ​​</h2>

        {selected_distributor_type === 'event' ? (
          <>
            {/* Event Section */}
            <h2
              style={{
                marginTop: '10%',
                fontWeight: 'normal',
                textAlign: 'center',
                fontSize: '20px',
              }}
            >
              Prenez votre ticket n° <b>{ticketNumber ? ticketNumber : '.........'}</b> et récupérez votre article au
              stand de&nbsp;
              <b>
                {vendorDetails?.vendor_info?.[0]?.['Company Name']
                  ? vendorDetails.vendor_info[0]['Company Name']
                  : '.........'}
              </b>
            </h2>

            <div style={{ marginTop: '5%', marginBottom: '20px' }}>
              {vendorDetails?.vendor_info?.[0]?.['Company Logo'] ? (
                <img
                  src={`data:image/png;base64,${vendorDetails.vendor_info[0]['Company Logo']}`}
                  alt={`Logo of ${vendorDetails.vendor_info[0]['Company Name']}`}
                  style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                />
              ) : (
                <div
                  style={{
                    width: '150px',
                    height: '150px',
                    backgroundColor: 'lightgray',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  Logo not available
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {/* Non-Event Section */}
            <h2
              style={{
                marginTop: '10%',
                fontWeight: 'normal',
                textAlign: 'center',
                fontSize: '20px',
              }}
            >
              Récupérer votre produit auprès d'un personnel de boutique
            </h2>

            <div style={styles.productInfo}>
              <div><img src={savedProduct.imageUrl} alt="Product" style={{ maxWidth: '30%', height: '100%' }} /></div> 
              <div style={styles.productName}>{savedProduct.name}</div>
              <div style={styles.productBrand}>{savedProduct.brand}</div>
              <div style={styles.productPrice}>{savedProduct.price} €</div>
              <div style={styles.productIngredients}>{savedProduct.quantity}</div>
            </div>
          </>
        )}

        {/* Third h2 - bigger, bold, brownish-pink color */}
        <h2 style={{ fontSize: '1.8rem', fontWeight: 'bold', color: '#d49292', marginTop: '2%' }}>
          Au revoir et restez fabuleux (se) !​
        </h2>

        {apiCallInProgress && renderLoadingSpinner()}

        <div className='submit-container'>
          <div className="white-banner">
            <button className="submit-button-fixed" onClick={handleSubmit}>Notez Halisi</button>
          </div>
        </div>
        <div className="footer hide-on-print" style={{backgroundColor: 'white', borderColor: 'white', boxShadow: 'none'}}></div>
      </div>
    </div>
  );
};
const styles = {
  productInfo: {
    width: '100%',
    textAlign: 'center',
    marginTop: '2%',
  },
  productName: {
    fontSize: '2.5vw',
    fontWeight: 'bold',
  },
  productBrand: {
    fontSize: '2.5vw',
  },
  productPrice: {
    fontSize: '4vw',
    fontWeight: 'bold',
    color: '#d49292',
  },
  productIngredients: {
    fontSize: '2.5vw',
  },
};
export default YesPage;