import React, { useState } from 'react';
import halisiPicture from '../assets/logos/halisi_picture.png';
import { useDispatch , useSelector } from 'react-redux';
import AlertMessage from './AlertMessage';
import './AlertMessage.css';
import axios from 'axios';

const DisplayVideo = ({ goToConsent }) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const store_id = useSelector(state => state.store_id);

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleClick = async () => {
    getProductCategories();
    await sleep(1000); // Ensure sleep is defined elsewhere in your code
    goToConsent();
  };

  const getProductCategories = () => {
    setApiCallInProgress(true);
    setIsSubmit(true);
    let data =
      {language: "FR",
        store_id: store_id.toString()
      };
      axios
        .post("https://hal-cos-prd-gwc-fnapp-v1.azurewebsites.net/api/readproductcategories", data)
                    .then((data) => {
                        let resp = data.data;
                        dispatch({ type: 'SET_PRODUCT_CATEGORIES', payload: resp['product_categories']}); // Dispatch action to save res object 
                        setApiCallInProgress(false);
                      }
                  )};

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      marginTop: '10%',
      padding: '0 10%', // Add padding for smaller screens
    }}>
      <div style={{
        textAlign: 'center',
        maxWidth: '80%',
      }}>
        <h2 style={{
          textAlign: 'center',
          fontSize: 'clamp(2.0rem, 2vw, 2.5rem)', // Responsive font size
          lineHeight: '1.2', // Adjust line height for better readability
          maxWidth: '600px',
          margin: '0 auto',
          padding: '0 1rem', // Optional: Add horizontal padding for smaller screens
        }}>
          Trouver le produit capillaire parfait grâce à notre assistant de produits Halisi
        </h2>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5%',
      }}>
        <img
          src={halisiPicture}
          alt="Halisi Assistant"
          style={{
            maxWidth: '50%',
            height: 'auto',
            objectFit: 'contain', // Ensures the image scales properly within the container
          }}
        />
      </div>
      <p style={{
        marginTop: '1rem',
        fontSize: 'clamp(0.8rem, 1.5vw, 1rem)', // Responsive font size
        maxWidth: '80%', // Limit width for readability on larger screens
        textAlign: 'center',
      }}>
        Les recommandations de produits capillaires sont fournies à titre informatif uniquement.
        Neotex SARL décline toute responsabilité quant à leur utilisation ou à leurs résultats.
      </p>
      <div className='submit-container'>
        <div className="white-banner" style={{
          backgroundColor: 'white',
          width: '100%',
          height: '200px', // Adjust this value as needed
          display: 'flex',
          alignItems: 'center',
          bottom: '0',
          justifyContent: 'center',
        }}>
          <button className="submit-button-fixed"
            onClick={handleClick}
            style={{ backgroundColor: '#d49292', fontSize: 'clamp(1rem, 3.2vw, 2rem)' }}>
            Allons-y!
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisplayVideo;
