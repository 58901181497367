import React, { useState } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';

const renderLoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

const NoPage = ({ goToRatingsPage }) => {
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const [apiCallInProgress, setApiCallInProgress] = useState(false); // State to track API call progress
  // const [reason, setReason] = useState(''); // State to track selected reason
  const vendorDetails = useSelector(state => state.vendorDetails);
  const reason = useSelector(state => state.reason); // Get reason from the store
  const distributor_info = useSelector(state => state.distributor_info);
  const distributorType = distributor_info.distributor_info[0]['Distributor Type'];

  const handleSubmit = () => {
    if (!reason) {
      // Show an alert if no reason is selected
      alert("Veuillez sélectionner une option dans le menu déroulant pour continuer.");
      return;
    }
    // Dispatch the last action and navigate to the ratings page
    dispatch({ type: 'SET_LAST_ACTION', payload: 'Purchase_No' });
    goToRatingsPage();
  };

  const handleDropdownChange = (e) => {
    const selectedReason = e.target.value;
    dispatch({ type: 'SET_REASON', payload: selectedReason }); // Dispatch action to save reason in the store
  };

  return (
    <div className="container">
      <div className="form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* First h2 - stays the same */}
        <h2 style={{ fontSize: '2.0rem' }}>Nous comprenons votre décision de ne pas acheter ce produit pour le moment ​​</h2>

        {/* Second h2 - less bold, spaced lower */}
        <h2 style={{ marginTop: '10%', fontWeight: 'normal', fontSize: '1.4rem'}}>
          Pour nous aider à nous améliorer, pouvez-vous nous dire pourquoi ?​​​​
        </h2>

        {/* Dropdown to select reason */}
        <select
          value={reason}
          onChange={handleDropdownChange} // Handle dropdown change
          style={{marginTop: '-2%', width: '50%', borderRadius: '10px' }}
        >
          <option value="">Sélectionnez une raison</option>
          <option value="Prix élevé">Prix élevé</option>
          <option value="Manque de temps">Manque de temps</option>
          <option value="Juste regarder">Juste regarder</option>
          <option value="Indisponibilité">Indisponibilité</option>
          <option value="Achat en ligne plus pratique">Achat en ligne plus pratique</option>
          <option value="Pas d'échantillons">Pas d'échantillons</option>
          <option value="Manque de confiance en la recommandation">Manque de confiance en la recommandation</option>
          <option value="Marque peu connue">Marque peu connue</option>
        </select>

        {/* Conditional Rendering Based on distributor_type */}
        <h2 style={{ marginTop: '10%', fontWeight: 'normal', fontSize: '1.4rem' }}>
          {distributorType === 'event'
            ? "Si vous avez besoin d'aide pour quoi que ce soit d'autres, n'hésitez pas à contacter​"
            : "Si vous avez besoin d'aide pour quoi que ce soit d'autres, n'hésitez pas à contacter un personnel de boutique"}
        </h2>

        {/* Placeholder space for logos */}
        <div style={{ marginTop: '-2%', display: 'flex', justifyContent: 'space-around', width: '100%' }}>
        {vendorDetails && vendorDetails['vendor_info'] && vendorDetails['vendor_info'].length > 0 ? (
            vendorDetails['vendor_info'].map((vendor, index) => (
              <div key={index} style={{ width: '20%', height: '20%' }}>
                {/* Render base64 logo */}
                {vendor['Company Logo'] ? (
                  <img
                    src={`data:image/png;base64,${vendor['Company Logo']}`} // Assuming the Company Logo is a valid base64 string
                    alt={`Logo of ${vendor['Company Name']}`}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                ) : (
                  <div style={{ width: '100%', height: '100%', backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    Logo not available
                  </div>
                )}
              </div>
            ))
          ) : (
            <div style={{ width: '100%', height: '100%', backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              No logos available
            </div>
          )}
        </div>

        {/* Third h2 - bigger, bold, brownish-pink color */}
        <h2 style={{ fontSize: '1.8rem', fontWeight: 'bold', color: '#d49292', marginTop: '5%' }}>
          Merci. Passez une excellente journée!​
        </h2>

        {apiCallInProgress && renderLoadingSpinner()}

        {/* Buttons */}
        <div className='submit-container'>
          <div className="white-banner">
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', marginTop: '-5%'}}>
              <button onClick={handleSubmit} style={{ backgroundColor: '#d49292', color: 'white', padding: '10px 20px', borderRadius: '20px', fontSize: '3.5vw', height: '60px', fontWeight: 'bold', textAlign: 'center', cursor: 'pointer' }}>
                Notez Halisi
              </button>
            </div>
          </div>
        </div>
        <div className="footer hide-on-print" style={{backgroundColor: 'white', borderColor: 'white', boxShadow: 'none'}}></div>
      </div>
    </div>
  );
};

export default NoPage;