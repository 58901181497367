import React, { useState } from 'react';
import productLogo from '../assets/logos/product_logo.png'; // Import your logo file
import neotexLogo from '../assets/logos/neotex_logo.png'; // Import your logo file
import '../App.css';
import { useDispatch } from 'react-redux'; // Import useDispatch hook
import { clearDiagnosticsLLM, clearProductLLM, clearSavedProduct, clearHairMask, clearDistributorLogo } from '../store';

const Landing = ({ goToAuthorisation}) => {
  const [showHeader, setShowHeader] = useState(false);
  const dispatch = useDispatch(); // Initialize dispatch function
  dispatch({ type: 'SET_CONSENT_FLAG', payload: false}); 

  const handleNextClick = () => {
    dispatch(clearProductLLM());
    dispatch(clearDiagnosticsLLM());
    dispatch(clearSavedProduct());
    dispatch(clearHairMask());
    dispatch(clearDistributorLogo());
    goToAuthorisation();
  };

  return (
    <div className="landing">
      {/* Company logo */}
      <div className="logo-container">
        <img src={productLogo} alt="Product Logo" className="logo" />
      </div>
      
      <div className="logo-container"style={{ position: 'absolute', bottom: '5px', left: '0', display: 'flex', justifyContent: 'center', width: '100%' }}>
      <p> Propulsé par l'Intelligence Artificielle​</p>
  </div>
      <div className="logo-container"style={{ position: 'absolute', bottom: '-50px', left: '0', display: 'flex', justifyContent: 'center', width: '100%' }}>
      <img src={neotexLogo} alt="Product Logo"
  style={{maxWidth: '70px',height: '70px'
  }}
/>
  
      </div>
      <div className="footer">
          <button onClick={handleNextClick} disabled={true}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
      </div>
    </div>
  );
};

export default Landing;