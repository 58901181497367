import React, { useState } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from './AlertMessage';
import './AlertMessage.css';
import axios from 'axios';

const renderLoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

const RatingsPage = ({ goToDisplayVideo, goToLanding }) => {
  const dispatch = useDispatch();
  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDataAlert, setShowDataAlert] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [enableButton, setEnableButton] = useState(false);

  const [recommendRating, setRecommendRating] = useState(0);
  const [satisfactionRating, setSatisfactionRating] = useState(0);
  const [openReview, setOpenReview] = useState('');

  // call back data from the store
  const selectedCategory = useSelector(state => state.selectedCategory);
  const saved_answers = useSelector(state => state.saved_answers);
  const saved_constraints = useSelector(state => state.saved_constraints);
  const productRecommendations = useSelector(state => state.productRecommendations);
  const diagnosticsLLMText = useSelector(state => state.diagnosticsLLMText);
  const productLLMText = useSelector(state => state.productLLMText);
  const savedProduct = useSelector(state => state.savedProduct);
  const productIndex = useSelector(state => state.productIndex);
  const ticketNumber = useSelector(state => state.ticketNumber);
  const realHairSegment = useSelector(state => state.realHairSegment);
  const lastAction = useSelector(state => state.lastAction);
  const no_reason = useSelector(state => state.reason);

  const emojis = [
    { id: 1, symbol: '😠', label: 'Very Difficult' },
    { id: 2, symbol: '☹️', label: 'Difficult' },
    { id: 3, symbol: '😐', label: 'Neutral' },
    { id: 4, symbol: '🙂', label: 'Easy' },
    { id: 5, symbol: '😍', label: 'Very Easy' },
  ];

  const handleEmojiClick = (id, setRating) => {
    setRating(id);
  };


  const createFeedbackRecord = (recommendRating, satisfactionRating, openReview) => {
    const feedback = {
      'recommendations_rating': recommendRating,
      'satisfaction_rating': satisfactionRating,
      'open_review': openReview,
      'saved_answers': saved_answers,
      'selected_category': selectedCategory,
      'saved_constraints': saved_constraints,
      'product_recommendations': productRecommendations,
      'diagnostics_LLM_text': diagnosticsLLMText,
      'product_LLM_text': productLLMText,
      'saved_product': savedProduct,
      'product_index': productIndex,
      'ticketNumber': ticketNumber,
      'hair_mask': realHairSegment,
      'last_action': lastAction,
      'no_reason': no_reason,
      'env': 'Prd',
    };
    let data = {
      feedback: feedback
    };
    setApiCallInProgress(true);
    axios.post("https://hal-cos-prd-gwc-fnapp-v1.azurewebsites.net/api/createfeedbackrecord", (data),
    {headers: {
      'Content-Type': 'application/json; charset=utf-8' }})
    .then((data) => {
        let resp = data.data;
        setApiCallInProgress(false);
        setShowDataAlert(true);
      })
      .catch((error) => {
          setApiCallInProgress(false); // Stop spinner
          alert("Une erreur est survenue lors de l'envoi de vos données. Veuillez réessayer plus tard.");
      });
  };

  const handleSubmit = () => {
    // Check if both ratings are filled in
    if (recommendRating === 0 || satisfactionRating === 0) {
        setShowAlert(true); // Show an alert if ratings are missing
        return;
    }

    // Proceed if both ratings are filled in
    setSuccessfulValidation(true);
    createFeedbackRecord(recommendRating, satisfactionRating, openReview);
    setShowDataAlert(true);
  };

  // New alert handling function
  const handleAlertConfirm = () => {
      setShowAlert(false); // Hide the alert when confirmed
  };

  const renderEmojiRating = (rating, setRating) => {
    return (
      <div style={{ display: 'flex', gap: '15px', marginTop: '10px' }}>
        {emojis.map((emoji) => (
          <span
            key={emoji.id}
            role="img"
            aria-label={emoji.label}
            onClick={() => handleEmojiClick(emoji.id, setRating)}
            style={{
              fontSize: '2rem',
              cursor: 'pointer',
              color: emoji.id === rating ? 'orange' : '#fff', // Only highlight the selected emoji
              border: emoji.id === rating ? '2px solid orange' : '2px solid transparent', // Only show border for the selected emoji
              borderRadius: '50%',
              padding: '5px',
            }}
          >
            {emoji.symbol}
          </span>
        ))}
      </div>
    );
  };

  const handleConfirm = () => {
    setShowDataAlert(false);
    goToDisplayVideo();
  };

  const handleCancel = () => {
    setShowDataAlert(false);
    goToLanding();
  };

  return (
    <div className="container">
      <div className="form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2 style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#d49292' }}>
          Vos commentaires comptent!<br />Evaluez Halisi​​
        </h2>

        <h3 style={{ marginTop: '10%', fontWeight: 'normal' }}>
        Êtes-vous satisfait(e) de Halisi ?​
        </h3>
        {renderEmojiRating(satisfactionRating, setSatisfactionRating )} {/* Emoji rating component for recommendation */}

        <h3 style={{ marginTop: '10%', fontWeight: 'normal' }}>
          Recommanderiez-vous Halisi à un(e) ami(e) ?​
        </h3>
        {renderEmojiRating(recommendRating, setRecommendRating)} {/* Emoji rating component for satisfaction */}

        <h3 style={{ marginTop: '10%', fontWeight: 'normal' }}>
          Laissez-nous un commentaire libre sur votre expérience :​
        </h3>
        <textarea
          style={{ width: '80%', height: '100px', padding: '10px', fontSize: '1rem' }}
          placeholder="Tapez votre avis ici..."
          value={openReview}
          onChange={(e) => setOpenReview(e.target.value)}
        />

        {apiCallInProgress && renderLoadingSpinner()}

        <div className='submit-container' style={{ marginTop: '20%' }}>
          <div className="white-banner">
            <button className="submit-button-fixed" onClick={handleSubmit}>Soumettre</button>
          </div>
        </div>

        {showDataAlert && (
          <AlertMessage
            message="Voulez-vous redémarrer le processus ?"
            subtext="Merci pour votre évaluation!"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}

        {showAlert && (
              <AlertMessage
                  message="Veuillez remplir les évaluations avant de soumettre."
                  onConfirm={handleAlertConfirm}
              />
          )}
      </div>
    </div>
  );
};

export default RatingsPage;