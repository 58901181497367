import React, { useState, useEffect } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import productLogo from '../assets/logos/product_logo.png'; // Import your logo file
import Login from './Login';
import {GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch , useSelector } from 'react-redux';
import AlertMessage from './AlertMessage';
import './AlertMessage.css';
import axios from 'axios';

const Authorisation = ({ goToLanding, goToStoreSelector }) => {
  const isLoggedIn = useSelector(state => state.isLoggedIn);
  const [showAlert, setShowAlert] = useState(false); // State to control the visibility of the alert
  const apiStatus = useSelector(state => state.apiStatus);
  const dispatch = useDispatch();
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const distributor_id = useSelector(state => state.distributor_id);


  const renderLoadingSpinner = () => {
    return (
      <div className="loading-spinner-container-auth">
        <div className="loading-spinner-auth"></div>
      </div>
    );
  };

  const getStoreInfo = () => {
    setApiCallInProgress(true);
    console.log(distributor_id);
    let data = 
    {distributor_id: distributor_id,
      env: 'Prd'
    };
    axios
        .post("https://hal-cos-prd-gwc-fnapp-v1.azurewebsites.net/api/readdistributordetails", data)
        .then((data) => {
            let res = data.data;
            setApiCallInProgress(false);
            console.log(res);
            dispatch({ type: 'DISTRIBUTOR_INFO', payload: res});
            sleep(200);
            })
            .catch((err) => {
            if (err.response.status === 501 | err.response.status === 404)
              console.log('error')
            });
        };

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleNextClick = async () => {
    if (isLoggedIn){
      getStoreInfo();
      await sleep(1000);
      goToStoreSelector();
    }
    else {
      setShowLoginAlert(true);
    }
  };

  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleGoogleLoginFailure = () => {
    // Handle the Google authentication response here
    setShowFailureAlert(true);
  };

  const handleGoogleLoginSuccess = () => {
    // Handle the Google authentication response here
    setShowSuccessAlert(true);
  };

  const handleConfirm = () => {
      setShowAlert(false); // Hide the alert
      goToLanding();
    }

  const handleCancel = () => setShowAlert(false)

  return (
    <div className="landing">
      {/* Company logo */}
      <div className="logo-container">
        <img src={productLogo} alt="Product Logo" className="logo" />
      </div>
      <div className="google-container">
        <p className="welcome-back">Content de vous revoir!</p>
        {/* Google Login button */}
        <GoogleOAuthProvider clientId="275717996013-bndc08evgidtut5c6pf6rmt51j0csl9j.apps.googleusercontent.com">
          <Login handleGoogleLoginSuccess={handleGoogleLoginSuccess} handleGoogleLoginFailure={handleGoogleLoginFailure} setApiCallInProgress={setApiCallInProgress}></Login>
        </GoogleOAuthProvider>
        <div className='spinner'>
          {apiCallInProgress && renderLoadingSpinner()}
        </div>
        {/* Navigation buttons */}
      </div>

      <div className="footer">
        <button onClick={handlePrevClick}>
          <span className="symbol">&#60;</span>
        </button>
        <button onClick={handleNextClick}>
          <span className="symbol">&#62;</span>
        </button>
      </div>
      {/* Render the alert message if showAlert is true */}
      {showAlert && (
        <AlertMessage
          message="Êtes-vous sûr de vouloir continuer ?"
          subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      {showLoginAlert && (
        <AlertMessage
          message="Veuillez vous connecter avec votre compte Google pour continuer à accéder aux services Halisi."
          onConfirm={() => {
            setShowLoginAlert(false);
        }}
        />
      )}
      
      {showFailureAlert && (
        <AlertMessage
          message="Veuillez vérifier vos identifiants de connexion et réessayer."
          onConfirm={() => {
            setShowFailureAlert(false);
        }}
        />
      )}
      {showSuccessAlert && (
        <AlertMessage
          message="Bienvenue ! Vous êtes authentifié et prêt à commencer. N’hésitez pas à continuer."
          onConfirm={async () => {
            getStoreInfo();
            await sleep(1000);
            goToStoreSelector();
            setShowSuccessAlert(false);
          }}
        />
      )}
    </div>
  );
};


export default Authorisation;