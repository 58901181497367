import React, { useState,useEffect } from 'react';
import '../App.css';
import { useGoogleLogin } from '@react-oauth/google';
import googleLoginImage from '../assets/logos/google_login_button.png'; 
import axios from 'axios';
import { useDispatch } from 'react-redux'; // Import useDispatch hook

const Login = ({handleGoogleLoginSuccess, handleGoogleLoginFailure}) => {
  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isApproved, setIsApproved] = useState();
  const dispatch = useDispatch(); // Initialize dispatch function

  const login = useGoogleLogin({
  onSuccess: (codeResponse) => {
    dispatch({ type: 'SET_API_STATUS', payload:true});
    setUser(codeResponse)
  },
  onError: (error) => {
    dispatch({ type: 'SET_API_STATUS', payload:true});
  },
  select_account: 'true'
  });


  const queryApprovedEmailID = (email_id) => {
    let data = 
    {email_id: email_id,
      env: 'Prd'
    };
    console.log('in the next api call', email_id);
    axios
        .post("https://hal-cos-prd-gwc-fnapp-v1.azurewebsites.net/api/verifyemailid", data)
        .then((data) => {
            let res = data.data;
            console.log('response of login request is:', res)
            dispatch({ type: 'DISTRIBUTOR_ID', payload:res['distributor_id']});
            setIsApproved(res.status);
            })
            .catch((err) => {
            if (err.response.status === 501 | err.response.status === 404)
              setIsApproved(true);
            });
        };

  useEffect(
      () => {
        if (user) {
            axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                setProfile(res.data);
                const status = queryApprovedEmailID(res.data.email);
                if (isApproved===true){
                  dispatch({ type: 'AGENT_AUTH_PROFILE', payload:profile});
                  dispatch({ type: 'LOGIN_STATUS', payload:isLoggedIn});
                  dispatch({ type: 'SET_API_STATUS', payload:false});
                  handleGoogleLoginSuccess();
                };
                if (isApproved===false){
                  dispatch({ type: 'SET_API_STATUS', payload:false});
                  handleGoogleLoginFailure()};
            })
            .catch((err) => 
            console.log(err));
        }
    },
    [ user, dispatch, isApproved ]
  );
 
  return(
      <button className='google-login-button' onClick={() => login()}>
        <img className="google-login-image" src={googleLoginImage} alt="Sign In With Google"/>
      </button>
  );
};

export default Login;