import { configureStore } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  isSubmit: false,
  consent_flag: true,
  childage: null,
  profile:'',
  isLoggedIn: false,
  logoutProfile: null,
  saveSelectedButtons: [],
  hair_class: null,
  hair_state: null,
  hair_goals: null,
  hair_challenges: null,
  scalp_challenges: null,
  categories: [],
  contraindications: null,
  selectedCategory: null,
  productRecommendations: null,
  productIndex: null, 
  savedProduct: null,
  vendorDetails: null,
  ticketNumber: null,
  capturedImage: null,
  diagnosticsLLMText: '',
  productLLMText: '',
  saved_answers: null,
  saved_constraints: null,
  segmentedImage: null,
  realHairSegment: null,
  lastAction: null,
  isAnalyzeDisabled: false,
  reason: '',
  distributor_id: null,
  distributor_info: null,
  store_id: null,
  selected_distributor_logo: null,
  selected_distributor_name: null,
  selected_distributor_type: null,
  enableHairSegmentation: true
};


// Define reducer function
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CAPTURED_IMAGE':
      return {
        ...state,
        capturedImage: action.payload,
      };
    case 'SET_IS_SUBMIT':
      return {
        ...state,
        isSubmit: action.payload,
      };
    case 'AGENT_AUTH_PROFILE':
      return {
        ...state,
        profile: action.payload,
      };
    case 'LOGOUT_AGENT_PROFILE':
      return {
        ...state,
        profile: initialState.profile,
      };
    case 'LOGIN_STATUS':
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case 'SET_CONSENT_FLAG':
      return {
        ...state,
        consent_flag: action.payload,
      };
    case 'SAVE_CHILD_AGE_RESPONSE':
      return {
        ...state,
        childage: action.payload,
      };
    case 'SAVED_HAIR_CLASS':
      return {
        ...state,
        hair_class: action.payload,
      };
    case 'SAVED_HAIR_STATE':
      return {
        ...state,
        hair_state: action.payload,
      };
    case 'SAVED_HAIR_GOALS':
      return {
        ...state,
        hair_goals: action.payload,
      };
    case 'SAVED_HAIR_CHALLENGES':
      return {
        ...state,
        hair_challenges: action.payload,
      };
    case 'SAVED_SCALP_CHALLENGES':
      return {
        ...state,
        scalp_challenges: action.payload,
      };
    case 'SET_PRODUCT_CATEGORIES':
      return {
        ...state,
        categories: action.payload,
      };
    case 'SAVE_CONTRAINDICATIONS':
      return {
        ...state,
        contraindications: action.payload,
      };
    case 'SELECTED_CATEGORY':
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case 'SET_PRODUCT_RECOMMENDATIONS':
      return {
        ...state,
        productRecommendations: action.payload,
      };
    case 'PRODUCT_INDEX':
      return {
        ...state,
        productIndex: action.payload,
      };
    case 'SELECTED_DISTRIBUTOR_LOGO':
      return {
        ...state,
        selected_distributor_logo: action.payload,
      };
    case 'SELECTED_DISTRIBUTOR_NAME':
      return {
        ...state,
        selected_distributor_name: action.payload,
      };
    case 'SELECTED_DISTRIBUTOR_TYPE':
      return {
        ...state,
        selected_distributor_type: action.payload,
      };
    case 'SAVE_SELECTED_PRODUCT':
      return {
        ...state,
        savedProduct: action.payload,
      };
    case 'SET_VENDOR_DETAILS':
      return {
        ...state,
        vendorDetails: action.payload,
      };
    case 'TICKET_NUMBER':
      return {
        ...state,
        ticketNumber: action.payload,
      };
    case 'DIAGNOSTICS_LLM_TEXT':
      return {
        ...state,
        diagnosticsLLMText: action.payload,
      };
    case 'PRODUCT_LLM_TEXT':
        return {
          ...state,
          productLLMText: action.payload,
        };
    case 'CLEAR_SAVED_PRODUCT':
      return {
        ...state,
        savedProduct: initialState.savedProduct,
      };
    case 'SAVED_FORM_ANSWERS':
      return {
        ...state,
        saved_answers: action.payload,
      };
    case 'SAVED_CONSTRAINTS':
        return {
          ...state,
          saved_constraints: action.payload,
        };
    case 'CLEAR_DIAGNOSTICS_LLM':
      return {
        ...state,
        diagnosticsLLMText: initialState.diagnosticsLLMText,
      };
    case 'CLEAR_PRODUCT_LLM':
      return {
        ...state,
        productLLMText: initialState.productLLMText,
      };
    case 'SET_SEGMENTATION_RESPONSE_IMG_SRC':
      return {
        ...state,
        segmentedImage: action.payload,
      };
    case 'SET_REAL_HAIR_SEGMENT':
        return {
          ...state,
          realHairSegment: action.payload,
        };
    case 'SET_ANALYSE_BUTTON_STATUS':
      return {
        ...state,
        isAnalyzeDisabled: action.payload,
      };
    case 'SET_LAST_ACTION':
      return {
        ...state,
        lastAction: action.payload,
      };
    case 'SET_REASON':
      return {
        ...state,
        reason: action.payload,
      };
    case 'CLEAR_CAPTURED_IMAGE':
      return {
        ...state,
        capturedImage: initialState.capturedImage,
      };
       
    case 'DISTRIBUTOR_ID':
      return {
        ...state,
        distributor_id: action.payload,
      };
    case 'STORE_ID':
      return {
        ...state,
        store_id: action.payload,
      };
    case 'DISTRIBUTOR_INFO':
      return {
        ...state,
        distributor_info: action.payload,
      };
    case 'CLEAR_HAIR_MASK':
      return {
        ...state,
        realHairSegment: initialState.realHairSegment,
      };   
    case 'ENABLE_HAIR_SEGMENTATION':
      return {
        ...state,
        enableHairSegmentation: action.payload,
      };     
    default:
      return state; // Return the current state by default
  }
};

// Create Redux store
const store = configureStore({
  reducer,
});

export default store;

// Action creators

export const clearDiagnosticsLLM = () => ({
  type: 'CLEAR_DIAGNOSTICS_LLM',
});

export const clearProductLLM = () => ({
  type: 'CLEAR_PRODUCT_LLM',
});

export const clearSavedProduct = () => ({
  type: 'CLEAR_SAVED_PRODUCT',
});

export const setProductIndex = (data) => ({
  type: 'PRODUCT_INDEX',
  payload: data,
});

export const clearFormData = () => ({
  type: 'CLEAR_FORM_DATA',
});

export const clearFormDataLivestock = () => ({
  type: 'CLEAR_FORM_DATA_LIVESTOCK',
});
export const logoutProfile = () => ({
  type: 'LOGOUT_AGENT_PROFILE',
});
export const saveSelectedButtons = () => ({
  type: 'SAVE_SELECTED_BUTTONS',
});
export const setCapturedImage = (image) => ({
  type: 'SET_CAPTURED_IMAGE',
  payload: image,
});
export const setSegmentationResponseImgSrc = (src) => ({
  type: 'SET_SEGMENTATION_RESPONSE_IMG_SRC',
  payload: src,
});
export const clearCapturedImage = () => ({
  type: 'CLEAR_CAPTURED_IMAGE',
});
export const setRealHairSegment = (src) => ({
  type: 'SET_REAL_HAIR_SEGMENT',
  payload: src,
});
export const setSavedProduct = (savedProduct) => ({
  type: 'SAVE_SELECTED_PRODUCT',
  payload: savedProduct,
});
export const clearHairMask = () => ({
  type: 'CLEAR_HAIR_MASK',
});
export const clearDistributorLogo = () => ({
  type: 'SELECTED_DISTRIBUTOR_LOGO',
});
export const setAnalyseButtonStatus = (isAnalyzeDisabled) => ({
  type: 'SET_ANALYSE_BUTTON_STATUS',
  payload: isAnalyzeDisabled,
});
export const setReason = (reason) => ({
  type: 'SET_REASON',
  payload: reason,
});