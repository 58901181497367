import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Camera from '../components/Camera';
import '../App.css';
import AlertMessage from '../components/AlertMessage';
import { toUnicodeVariant } from '../utils';
import axios from 'axios';
import { clearCapturedImage } from '../store';
import { setSegmentationResponseImgSrc, setAnalyseButtonStatus } from '../store';

const renderLoadingSpinner = () => {
  return (
    <div className="loading-spinner-container-request">
      <div className="loading-spinner-request" style={{marginTop: '90%'}}></div>
    </div>
  );
};

const HairSegmentation = ({ goToVisualConfirmation, goToOverview }) => {
  const capturedImage = useSelector(state => state.capturedImage);
  const [selectionMode, setSelectionMode] = useState('automatic');
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [APIResponseImgSrc, setAPIResponseImgSrc] = useState(null);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const [successfulAPIcall, setSuccessfulAPIcall] = useState(false);
  const [analyserClicked, setAnalyserClicked] = useState(true);
  const base64Header = "data:image/jpeg;base64,";
  const isAnalyzeDisabled = useSelector((state) => state.isAnalyzeDisabled);


  const processSegmentation = async () => { 
    setApiCallInProgress(true);
    if (!capturedImage ) {
      alert("Pour procéder à la vérification visuelle, veuillez prendre une photo en appuyant sur le bouton " + toUnicodeVariant('CAPTURER', 'bold sans', 'bold') + ", puis soumettez-la en appuyant sur le bouton" + toUnicodeVariant('ANALYSER', 'bold sans', 'bold'));
      setApiCallInProgress(false);
    }
    else {
        if (!isAnalyzeDisabled){
              dispatch(setAnalyseButtonStatus(true));
              let t0 = performance.now();
              let data =
                  {image: capturedImage};
                  axios
                  .post("https://halisi-hair-segmentation.azurewebsites.net/api/createsegmentationmask", data)
                  .then((data) => {
                      let resp = data.data;
                      console.log(resp);
                      if (resp.segmented_image){
                      setAPIResponseImgSrc(base64Header + resp.segmented_image);
                      dispatch({ type: 'SET_SEGMENTATION_RESPONSE_IMG_SRC', payload: base64Header + resp.segmented_image }); // Dispatch action to save res object
                      dispatch({ type: 'SET_REAL_HAIR_SEGMENT', payload: base64Header + resp.real_hair_segmented }); // Dispatch action to save res object
                      let t1 = performance.now();
                      let total = parseInt(t1 - t0);
                      setSuccessfulAPIcall(true);
                      setIsSuccess(true);
                      setApiCallInProgress(false);
                      dispatch({ type: 'SET_API_RESPONSE_IMG_SRC', payload:null});
                      }
                      else {
                        setSuccessfulAPIcall(true);
                        setIsSuccess(false);
                        setApiCallInProgress(false);
                      }
                }).catch((err) => {
                  if (err.response.status === 501 | err.response.status === 404)
                  setIsSuccess(false);
                  dispatch({ type: 'SET_API_RESPONSE_IMG_SRC', payload:null});
                  setApiCallInProgress(false);
                  alert("Visage non détecté. Veuillez réessayer en capturant un visage humain dans le cadre fourni, en vous assurant que l'image est prise dans des conditions d'éclairage normales."); 
                });
          }
          else{
            alert("Veuillez cliquer sur le " + toUnicodeVariant('Diffuser', 'bold sans', 'bold') + " suivi du " + toUnicodeVariant('Capturer', 'bold sans', 'bold') + " bouton pour continuer.");
            setApiCallInProgress(false);
          };
      } 
    };

  // Navigate to previous page
  const handlePrevClick = () => {
    setShowAlert(true);
    setAPIResponseImgSrc(null); 
    setIsSuccess(false);
  };

  // Navigate to next page
  const handleNextClick = () => {
    if (isSuccess){
    dispatch(clearCapturedImage());
    setAPIResponseImgSrc(null); // Clear the segmentation output image
    setIsSuccess(false); // Reset success status
    goToOverview();

    }
    else {
      setShowErrorAlert(true);
    }
  };

  return (
    <div className="container">
      <div className="form">
        <h2 style={{ fontSize: '2rem' }}>Gardez votre visage droit face à la caméra</h2>
        <div style={{ marginTop: '5%', marginRight: '10%' }}>
          <Camera mode={selectionMode} />
        </div>
        {apiCallInProgress && renderLoadingSpinner()}
        <div className="submit-container">
          <div className="white-banner">
            <button className="submit-button-fixed" onClick={processSegmentation} style={{ bottom: '10%'}}>Analyser</button>
          </div>
        </div>
        {showAlert && (
          <AlertMessage
            message="Êtes-vous sûr de vouloir continuer ?"
            subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
            onConfirm={() => {
              setShowAlert(false);
              goToVisualConfirmation();
            }}
            onCancel={() => {
              setShowAlert(false);
            }}
          />
        )}
      {showErrorAlert && (
        <AlertMessage
            message={
            "Veuillez cliquer sur le " +
            toUnicodeVariant('ANALYSER', 'bold sans', 'bold') +
            " bouton pour continuer."
          }
          onConfirm={() => {
            setShowErrorAlert(false);
          }}
        />
      )}
        <div className="footer">
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HairSegmentation;